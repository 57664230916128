/* ==========================================================================================
  Parcel Tracker Icons
========================================================================================== */

@font-face {
  font-family: 'parceltracker';
  src: url('../../../../../assets/fonts/parceltracker.eot?kd7jdz');
  src: url('../../../../../assets/fonts/parceltracker.eot?kd7jdz#iefix') format('embedded-opentype'),
    url('../../../../../assets/fonts/parceltracker.ttf?kd7jdz') format('truetype'),
    url('../../../../../assets/fonts/parceltracker.woff?kd7jdz') format('woff'),
    url('../../../../../assets/fonts/parceltracker.svg?kd7jdz#parceltracker') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@import url(https://use.typekit.net/xoi5ihh.css);

.main-content {
  height: 100%;
}

.pt {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'sofia-pro' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.building:before {
  content: '\e900';
  color: #ffd600;
}
.briefcase:before {
  content: '\e901';
  color: #1f818c;
}
.search:before {
  content: '\e902';
  color: #279aa7;
}
.parcel:before {
  content: '\e903';
  color: #2dce89;
}
.stats:before {
  content: '\e904';
  color: #172b4d;
}
.cloud:before {
  content: '\e905';
}
.document:before {
  content: '\e906';
}
.tutorials:before {
  content: '\e907';
  color: #7f7f7f;
}
.privacy-policy:before {
  content: '\e908';
  color: rgba(0, 0, 0, 0.5);
}
.get-started:before {
  content: '\e909';
  color: #5e72e4;
}
.residents-directory:before {
  content: '\e90a';
  color: #f5365c;
}

/* ==========================================================================================
    Libs
  ========================================================================================== */
.csv-link-hidden {
  display: none;
}

.infinite-scroll-component {
  height: calc(100vh - 410px) !important;
  &.deliveries-scroll-table {
    height: calc(100vh - 435px) !important;
  }
}

.custom-toggle-slider {
  border: 2px solid #ced4da;
}

.custom-toggle-slider:before {
  bottom: 1px;
  left: 1px;
}

.custom-toggle input:checked + .custom-toggle-slider:before {
  background: #1f818c;
}

.custom-toggle input:checked + .custom-toggle-slider {
  border: 2px solid #1f818c;
}

.table.align-items-center td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 1px;
}

.table.align-items-center .td-img {
  text-overflow: initial;
}

/* Slider
    -------------------------------------------------------------------------------*/

.horizontal-slider {
  width: 100%;
  height: 20px;
  margin-bottom: 10px;
}

.horizontal-slider .example-track {
  top: 8px;
  height: 5px;
}

.example-track {
  position: relative;
  background: #1f818c;
}
.example-track.example-track-1 {
  background: #eceeef;
}

.horizontal-slider .example-thumb {
  width: 20px;
  height: 20px;
}

.example-thumb {
  background-color: #1f818c;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  box-sizing: border-box;
}

/* ==========================================================================================
    Utilities
  ========================================================================================== */

.error-message {
  color: #de2e2e;
  font-size: 13px;
  margin: 10px 0 0 0;
}

.d-flex-1 {
  flex: 1;
}

.form-control {
  color: #525f7f;
}

.calendly-button {
  a {
    padding: 1.5rem;
    color: inherit;
    text-decoration: noen;
  }
}

.full-height-card {
  min-height: calc(100vh - 155px);
  background: transparent;
  box-shadow: none;
}

.full-height-fixed-card {
  height: calc(100vh - 155px);
  background: transparent;
  box-shadow: none;
}

.card-reset {
  background: none;
  box-shadow: none;
}

// Icons
.ni {
  vertical-align: middle;
}

.login-create-account {
  color: #1f818c;
}

// Loader
.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  img {
    width: 50px;
  }
}

// Loader
.loader-container-parcels {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  width: 100%;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  img {
    width: 50px;
  }
}
/* ==========================================================================================
    Auth
  ========================================================================================== */

// Auth
.auth-form-container {
  width: 400px;
  align-self: flex-start;
  margin-top: 40px !important;
  &.login {
    margin-top: 80px !important;
  }
}

@media screen and (min-width: 768px) {
  .auth-form-container {
    width: 400px;
    align-self: initial;
    margin-top: initial;
  }
}

.pt-logo {
  position: fixed;
  top: 20px;
  width: 330px;
  margin-bottom: 100px !important;
  z-index: 1;
}

@media only screen and (max-width: 480px) {
  .pt-logo {
    position: initial;
    top: initial;
    width: 50%;
    margin-bottom: 25px !important;
  }
}
@media only screen and (max-height: 800px) {
  .pt-logo {
    position: initial;
    top: initial;
    margin-bottom: 25px !important;
  }
}
/* ==========================================================================================
    Building banner
  ========================================================================================== */

.building-banner {
  // position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 100%;
  background: #1f818c;
  font-size: 14px;
  color: white;
  span {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
  }
  a {
    cursor: pointer;
    text-decoration: underline;
    color: inherit;
  }
}

.building-banner-mobile {
  padding: 5px;
  width: 100%;
  background: #1f818c;
  font-size: 14px;
  color: white;
  text-align: center;
  p {
    margin-bottom: 5px;
  }
  .buildings {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }
}

/* ==========================================================================================
    Form
  ========================================================================================== */

.form-control {
  height: auto;
  padding: 0.5rem 0.75rem;
}

.input-group-text {
  padding: 0.5rem;
}

select.form-control {
  option {
    &:disabled {
      background-color: #e9ecef;
    }
  }
}

.custom-file-label {
  border: 2px solid#8dcbd2 !important;
  &::after {
    height: 41px;
    border-left: 1px solid #dee2e6 !important;
  }
}

// .custom-file-label.active {
//   border: 2px solid #1f818c !important;
//   &::after {
//     border-left: 1px solid #dee2e6 !important;
//   }
// }

.input-group-alternative {
  box-shadow: none !important;
  //border: 2px solid #bebebe;
  border: 2px solid#8dcbd2;
  transition: all 0.15s ease-in-out;
  i {
    font-size: 18px;
  }
  input {
    color: #606973;
  }
  &.filter {
    input {
      color: #172b4d;
    }
    i {
      color: #172b4d;
    }
  }
}

.focused .input-group-alternative {
  box-shadow: none !important;
  border: 2px solid #1f818c;
  input {
    color: #606973;
  }
  textarea {
    color: #606973;
  }
  &.filter {
    input {
      color: #172b4d;
    }
    i {
      color: #172b4d;
    }
  }
}

.input-group-alternative--shadow {
  box-shadow: 0px 3px 6px rgba(50, 50, 93, 0.25), 5px 2px 6px rgba(0, 0, 0, 0.05);
  border: 0;
  transition: box-shadow 0.15s ease;
  input {
    color: #606973;
  }
  textarea {
    color: #606973;
  }
}

.focused .input-group-alternative--shadow {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

.input-group-error-message {
  font-size: 14px;
  color: #de2e2e;
  margin: 10px 0 0 0;
}

.form-group {
  &.error {
    .input-group {
      border: 2px solid #de2e2e;
    }
    .input-group-text {
      color: #de2e2e;
    }
    .form-control {
      color: #de2e2e;
      &::placeholder {
        color: #a90a0a;
      }
    }
  }
}

/* ==========================================================================================
    Searchbar
  ========================================================================================== */
.focused .search-bar {
  .input-group-text {
    color: #279aa7;
    border-color: #1f818c;
  }
  input {
    color: #279aa7;
    border-color: #1f818c;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #279aa7;
      opacity: 1; /* Firefox */
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #279aa7;
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #279aa7;
    }
  }
}

.search-bar {
  box-shadow: none !important;
  .input-group-text {
    background-color: 'white';
    color: #279aa7;
    border-width: 2px;
    border-color: #8dcbd2;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    padding: 10px 0 10px 20px;
    border-right: 0;
  }
  input {
    background-color: 'white';
    color: #279aa7;
    border-left: none;
    border-width: 2px;
    border-color: #8dcbd2;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #279aa7;
      opacity: 1; /* Firefox */
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #279aa7;
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #279aa7;
    }
  }
}

/* ==========================================================================================
    Daterange picker
  ========================================================================================== */

.react-bootstrap-daterangepicker-container-full {
  width: 100%;
}

.daterangepicker {
  td.active {
    background-color: #1f818c !important;
    color: white !important;
  }
  .ranges li.active {
    background-color: #1f818c !important;
    color: white !important;
  }
  .btn-primary {
    background-color: #1f818c !important;
    color: white !important;
    border-color: #1f818c !important;
    color: white !important;
  }
}

/* ==========================================================================================
    Navbar
  ========================================================================================== */

//Overriding cursor:
.navbar-expand .navbar-nav {
  cursor: default;
}

.navbar-top {
  &.bg-info {
    .nav-link {
      span {
        color: white !important;
      }
    }
    .sidenav-toggler-line {
      background-color: white;
    }
  }
}

.nav-item {
  &.active {
    background: #f4f5f7;
  }
}

/* ==========================================================================================
    Sidebar
  ========================================================================================== */

.sidenav-toggler-line {
  height: 2px;
  background-color: #a1a1a2;
  display: block;
  position: relative;
}

/* ==========================================================================================
    Restriction Banner
  ========================================================================================== */

.restriction-banner {
  min-height: calc(100vh - 130px);
  div {
    background-color: #f5365c;
    color: white;
    padding: 20px;
    p {
      margin: 0;
    }
  }
}

/* ==========================================================================================
    Parcel Modal
  ========================================================================================== */

.parcel-view-modal {
  max-width: 800px;
  .close-button {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    background: #1f818c;
    color: white;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 1;
  }
  .modal-body {
    padding: 1rem;
    min-height: 750px;
    .title {
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 40px;
    }
    .parcel-img {
      width: 100%;
      height: 315px;
      object-fit: cover;
      border-radius: 20px;
      margin-bottom: 20px;
    }
    .signature-img {
      width: 100%;
    }

    .signature-wrapper {
      display: flex;
      height: 315px;
      align-items: center;
      justify-content: center;
      border: 1px solid #bebebe;
      border-radius: 15px;
      padding: 30px;
      .signature-container {
        position: relative;
        height: 100%;
        .button-clear-signature {
          position: absolute;
          right: 10px;
          top: 10px;
          background: transparent;
          border: none;
          color: #1f818c;
          cursor: pointer;
        }
        .signature-canvas {
          width: 100%;
          height: 100%;
        }
        .button-finish-signature {
          position: absolute;
          right: 10px;
          bottom: 10px;
          background: transparent;
          border: none;
          color: #1f818c;
          cursor: pointer;
        }
      }
    }
    .parcel-form {
      .form-control:disabled {
        background-color: inherit;
        opacity: 1;
      }
    }
  }
}
/* ==========================================================================================
    Uploading Modal
  ========================================================================================== */

.uploading-modal {
  .modal-body {
    padding-bottom: 0;
  }
  .progress-container {
    width: 50%;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    margin: 0 auto;
    margin-top: 20px;
    p {
      margin: 0 15px 0 0;
    }
    .progress {
      flex: 1;
      margin: 0;
    }
  }
}

/* ==========================================================================================
    New Delivery Modal
  ========================================================================================== */

.new-delivery-modal {
  .suggestions-box {
    position: absolute;
    top: 46px;
    z-index: 2;
    width: 100%;
    background: white;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.17);
    p {
      padding: 10px;
      margin: 0;
      font-size: 15px;
      border-top: 1px solid #f6f9fc;
      &:hover {
        background-color: #f6f9fc;
      }
    }
  }
}

/* ==========================================================================================
    Mark as Collected Modal
  ========================================================================================== */

.mark-as-collected-modal {
  .signature-container {
    position: relative;
    .button-clear-signature {
      position: absolute;
      right: 10px;
      top: 10px;
      background: transparent;
      border: none;
      color: #1f818c;
    }
    .signature-canvas {
      width: 100%;
      height: 300px;
      box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.17);
    }
  }
}

/* ==========================================================================================
    Alert Modal
  ========================================================================================== */

.alert-title {
  text-align: center;
  color: #525f7f;
}

.alert-description {
  margin: 0;
  color: #525f7f;
  &.error {
    color: #f5365c;
    font-weight: bold;
  }
}

.alert-icon-container {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-bottom: 20px;
}

.alert-icon {
  font-size: 100px;
  color: #f5365c;
  margin: 0;
  &.warning {
    color: #fb6340;
  }
  &.success {
    color: #2ecc71;
  }
  &.ios-beta {
    color: #1f818c;
  }
}

.alert-icon-image {
  width: 120px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.alert-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.alert-modal-footer {
  justify-content: center;
}

/* ==========================================================================================
    Get Started View
  ========================================================================================== */
.parcel-img-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  .parcel-sample-img {
    width: 60%;
    margin-bottom: 30px;
  }
}

.step-container {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  h1 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 5px;
  }
  p {
    font-size: 15px;
    font-weight: normal;
    margin: 0;
  }
  .step {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: #1f818c;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin-right: 20px;
    margin-bottom: 15px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  .step-div-text {
    font-size: 15px;
  }
  .step-icon {
    display: inline-block;
    color: white;
    background: #1f818c;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    padding: 7px;
    img {
      width: 15px;
    }
  }
  span,
  a {
    color: #1f818c !important;
    cursor: pointer !important;
    font-weight: bold;
  }
  .fa-youtube {
    color: #f00;
    font-size: 25px;
    margin-right: 10px;
  }
}
@media screen and (min-width: 768px) {
  .step-container {
    display: flex;
    .step {
      margin-bottom: 0;
    }
  }
}

.avatar {
  background-color: #1f818c;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.text-muted {
  a {
    color: #1f818c;
  }
}

/* ==========================================================================================
    CSV Uploader
  ========================================================================================== */

.csv-uploader {
  // Main page
  .main-page {
    h1 {
      font-size: 20px;
      font-weight: bolder;
    }
    p {
      font-size: 15px;
    }
  }
  .card-table {
    img {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  .upload-tenant {
    width: 200px;
  }
  .card-body {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  .card-icon {
    margin-bottom: 20px;
    i {
      color: #172b4d !important;
      font-size: 80px;
      &:before {
        color: #172b4d !important;
      }
    }
  }
  .csv-card-text {
    font-size: 13px;
  }
  .error-message {
    color: #de2e2e;
    font-size: 16px;
    font-weight: bold;
  }
  .optional-message {
    color: #1f818c;
    font-size: 14px;
    font-weight: 500;
    .fa {
      color: #1f818c;
    }
  }
  .error-footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

// Dropzone
.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 25px;
  border-width: 2px;
  border-radius: 10px;
  border-color: #928787;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  cursor: pointer;
  p {
    font-size: 13px;
    color: #32325d;
    span {
      color: #1f818c;
    }
  }
  &.hover {
    border-color: #172b4d;
    transform: scale(1.01);
  }
  &.uploaded {
    border: 2px solid #172b4d;
  }
  .filename {
    font-size: 18px;
    color: #172b4d;
    margin: 0;
    font-weight: bold;
  }
  .close-file {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
    border: none;
    background: none;
    z-index: 99;
    cursor: pointer;
    i {
      color: #606973;
    }
  }
  .document-icon {
    font-size: 60px;
    color: #172b4d;
    margin-bottom: 20px;
  }
  .upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
  }
}

.custom-form {
  width: 100%;
  margin-bottom: 20px;
}

.button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0;
}

.alert-message {
  width: 100%;
  text-align: right;
  font-size: 14px;
  margin: 10px 0 0 0;
}

/* ==========================================================================================
    Directory Table
  ========================================================================================== */

.directory-table {
  thead {
    tr {
      th {
        text-transform: none;
        color: #32325d;
        border-top: 0px;
        font-size: 16px;
        font-weight: bolder;
      }
    }
  }
  .fa-check {
    color: #2dce89;
  }
  .error-message {
    color: #de2e2e;
    font-size: 14px;
    .fa-times {
      margin-right: 10px;
    }
  }

  .values-list {
    list-style: none;
    padding: 0;
    .error {
      font-size: 12px;
      font-weight: bolder;
      color: #de2e2e;
    }
  }
  select.form-control {
    option:disabled {
      background-color: #f1f1f1;
      color: #b9b9b9;
    }
  }
}

.error-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.bottom-buttons-container-table {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
}

/* ==========================================================================================
    Validation  Table
  ========================================================================================== */

.validation-table-container {
  .table-responsive {
    margin-bottom: 30px;
  }
  .table-responsive {
    max-height: calc(100vh - 400px);
    .table {
      thead {
        tr {
          th {
            text-transform: none;
            color: #32325d;
            border-top: 0px;
            font-size: 16px;
            font-weight: bolder;
          }
        }
      }
      tbody {
        tr {
          th {
            font-weight: lighter;
          }
        }
      }
      .validation-list {
        .error-row {
          cursor: pointer;
          span {
            color: #ff0000;
          }
          .edit-row {
            display: flex;
            align-items: center;
            .inline-editing-label {
              color: #ff0000;
              margin-bottom: 0;
            }
            .inline-editing-input {
              border: 1px solid #ff0000;
              margin: 0;
              padding: 2px;
              width: 100%;
              color: grey;
              background-color: transparent;
            }
            span {
              margin-left: 5px;
            }
          }
        }

        .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
          border-color: #2dce89;
          background-color: #2dce89;
        }
        .custom-control-input:active ~ .custom-control-label::before {
          background-color: #2dce89;
        }
        .valid-row {
          background-color: #cbf3e2;
        }
        .invalid-row {
          background-color: #ff9696;
        }
        .data-errors {
          color: #ff0000;
          font-weight: bolder;
        }
        .fa-check {
          color: #2dce89;
        }
        .fa-times {
          color: #ff0000;
        }
      }
    }
  }
}
/* ==========================================================================================
    Deliveries Directory Table
  ========================================================================================== */
.deliveries-directory-view {
  /*   ====================== Filters   ======================   */
  .date-select-filter {
    select {
      color: #172b4d;
      /* reset */
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
      /* Custom arrow */
      background-image: url('https://maxcdn.icons8.com/Share/icon/Arrows//expand_arrow1600.png');
      background-repeat: no-repeat;
      background-size: 15px;
      background-position: 95% 50%;
      padding-left: 40px !important;
    }
    .select-icon {
      position: absolute;
      left: 8px;
      top: 12px;
      z-index: 1;
      color: #172b4d;
      font-size: 15px;
    }
  }
  /*   ====================== Fixed header   ======================   */
  .table-header-sticky {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f9fc;
    position: relative;
    padding: 1rem 0;
    .column-img {
      width: 10%;
      display: flex;
      align-items: center;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    .column {
      display: flex;
      flex: 1;
      align-items: center;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      // padding: 0 50px 0 0;
      cursor: pointer;
      p {
        color: #606973;
        font-weight: bold;
        font-size: 11px;
        text-transform: uppercase;
        text-align: left;
        margin: 0;
      }
      i {
        color: #bdbcbc;
        font-size: 18px;
        line-height: 5px;
        &.active {
          color: #1f818c;
        }
      }
      .sort-icons {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        margin-left: 15px;
      }
    }
    .custom-control {
      width: 10%;
      padding-left: 52px;
    }
  }
  /*  ======================  Fixed header   ======================   */
  .table-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding: 20px;
    img {
      width: 30px;
    }
  }
  .no-records-found {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 300px);
    p {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .table-responsive {
    //height: calc(100vh - 400px);
    .parcels-table {
      table-layout: fixed;
      .td-10 {
        width: 10%;
      }
      .td-20 {
        width: 22.5%;
      }
      .td-30 {
        width: 30%;
      }
      td {
        cursor: pointer;
        color: #525f7f;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.5;
      }
      p {
        color: #525f7f;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.5;
      }
      .active {
        background-color: #4db7c3;
        color: white;
      }
      .parcel-img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 20px;
      }
      .parcel-img-container {
        width: 100px;
        height: 100px;
        background-size: cover;
      }
      .mark-as-collected {
        color: #f75676;
        cursor: pointer;
      }
      .mark-as-collected-tenant {
        font-weight: bolder;
      }
    }
  }
  .page-item .page-link,
  .page-item span {
    width: 36px !important;
    height: 36px !important;
    font-size: 12px;
    font-weight: bold;
  }
}
/* ==========================================================================================
    Residents Directory Table
  ========================================================================================== */

.residents-directory-view {
  /*   ====================== Fixed header   ======================   */
  .table-header-sticky {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f9fc;
    position: relative;
    padding: 1rem 0;
    .column {
      width: 30%;
      display: flex;
      align-items: center;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      // padding: 0 50px 0 0;
      cursor: pointer;
      p {
        color: #606973;
        font-weight: bold;
        font-size: 11px;
        text-transform: uppercase;
        text-align: left;
        margin: 0;
      }
      i {
        color: #bdbcbc;
        font-size: 18px;
        line-height: 5px;
        &.active {
          color: #1f818c;
        }
      }
      .sort-icons {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        margin-left: 15px;
      }
    }
    .custom-control {
      width: 10%;
      padding-left: 52px;
    }
  }
  /*  ======================  Fixed header   ======================   */
  .table-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding: 20px;
    img {
      width: 30px;
    }
  }
  .no-records-found {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 300px);
    p {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .table-responsive {
    //height: calc(100vh - 400px);
    .residents-table {
      table-layout: fixed;
      .td-10 {
        width: 10%;
      }
      .td-30 {
        width: 30%;
      }
      .active {
        background-color: #4db7c3;
        color: white;
      }
    }
  }
  .sms-notifications {
    .label-text {
      margin-left: 10px;
      font-size: 12px;
      font-weight: bold;
    }
    .custom-toggle input:checked + .custom-toggle-slider:before {
      background: #1f818c;
    }
  }
  .empty-form {
    h3 {
      margin-bottom: 30px;
    }
    i {
      font-size: 50px;
    }
  }
  .page-item .page-link,
  .page-item span {
    width: 36px !important;
    height: 36px !important;
    font-size: 12px;
    font-weight: bold;
  }
}

/* ==========================================================================================
    Users  Directory Table
  ========================================================================================== */

.users-directory-view {
  /*   ====================== Fixed header   ======================   */
  .table-header-sticky {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f9fc;
    position: relative;
    padding: 1rem 0;
    .column {
      width: 30%;
      display: flex;
      align-items: center;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      // padding: 0 50px 0 0;
      cursor: pointer;
      p {
        color: #606973;
        font-weight: bold;
        font-size: 11px;
        text-transform: uppercase;
        text-align: left;
        margin: 0;
      }
      i {
        color: #bdbcbc;
        font-size: 18px;
        line-height: 5px;
        &.active {
          color: #1f818c;
        }
      }
      .sort-icons {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        margin-left: 15px;
      }
    }
    .custom-control {
      width: 10%;
      padding-left: 52px;
    }
  }
  /*  ======================  Fixed header   ======================   */
  .table-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding: 20px;
    img {
      width: 30px;
    }
  }
  .no-records-found {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 300px);
    p {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .table-responsive {
    //height: calc(100vh - 400px);
    .users-table {
      table-layout: fixed;
      .td-10 {
        width: 10%;
      }
      .td-30 {
        width: 30%;
      }
      .td-33 {
        width: 33.3%;
      }
      .active {
        background-color: #4db7c3;
        color: white;
      }
    }
  }
  .sms-notifications {
    .label-text {
      margin-left: 10px;
      font-size: 12px;
      font-weight: bold;
    }
    .custom-toggle input:checked + .custom-toggle-slider:before {
      background: #1f818c;
    }
  }
  .empty-form {
    h3 {
      margin-bottom: 30px;
    }
    i {
      font-size: 50px;
    }
  }
  .page-item .page-link,
  .page-item span {
    width: 36px !important;
    height: 36px !important;
    font-size: 12px;
    font-weight: bold;
  }
}

/* ==========================================================================================
    Developments  Directory Table
  ========================================================================================== */

.developments-directory-view {
  .btn-active-development {
    background-color: #ffd600;
    border-color: #ffd600;
    color: white;
  }

  .btn-active-development:not(:disabled):not(.disabled):active,
  .btn-active-development:not(:disabled):not(.disabled).active,
  .show > .btn-active-development.dropdown-toggle {
    color: #fff;
    background-color: #f3cc00;
    border-color: #f3cc00;
  }

  /*   ====================== Fixed header   ======================   */
  .table-header-sticky {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f9fc;
    position: relative;
    padding: 1rem 0;
    .column {
      width: 30%;
      display: flex;
      align-items: center;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      // padding: 0 50px 0 0;
      cursor: pointer;
      p {
        color: #606973;
        font-weight: bold;
        font-size: 11px;
        text-transform: uppercase;
        text-align: left;
        margin: 0;
      }
      i {
        color: #bdbcbc;
        font-size: 18px;
        line-height: 5px;
        &.active {
          color: #1f818c;
        }
      }
      .sort-icons {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        margin-left: 15px;
      }
    }
    .custom-control {
      width: 10%;
      padding-left: 52px;
    }
  }
  /*  ======================  Fixed header   ======================   */
  .table-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding: 20px;
    img {
      width: 30px;
    }
  }
  .no-records-found {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 300px);
    p {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .table-responsive {
    //height: calc(100vh - 400px);
    .developments-table {
      table-layout: fixed;
      .td-10 {
        width: 10%;
      }
      .td-20 {
        width: 20%;
      }
      .td-25 {
        width: 25%;
      }
      // .active {
      //   background-color: rgba(255, 214, 0, 0.3);
      // }
      .active-icon {
        font-size: 16px;
        margin-right: 10px;
        &:before {
          color: #1f818c;
        }
      }
      .active {
        background-color: #4db7c3;
        color: white;
        .active-icon {
          &:before {
            color: white;
          }
        }
      }
    }
  }
  .sms-notifications {
    .label-text {
      margin-left: 10px;
      font-size: 12px;
      font-weight: bold;
    }
    .custom-toggle input:checked + .custom-toggle-slider:before {
      background: #1f818c;
    }
  }
  .empty-form {
    h3 {
      margin-bottom: 30px;
      text-align: center;
    }
    i {
      color: #525f7f;
      font-size: 50px;
      &:before {
        color: #525f7f;
      }
    }
  }
  .page-item .page-link,
  .page-item span {
    width: 36px !important;
    height: 36px !important;
    font-size: 12px;
    font-weight: bold;
  }
}

/* ==========================================================================================
    Profile
  ========================================================================================== */

.security-header {
  margin-bottom: 20px;
  h6 {
    margin-bottom: 0 !important;
  }
}

/* ==========================================================================================
    Scrollbar
  ========================================================================================== */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fafafa;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1f818c;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1f818c;
}

div::-webkit-scrollbar {
  height: 7px;
}
div::-webkit-scrollbar-track {
  background: #fafafa;
  border-radius: 10px;
  webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
div::-webkit-scrollbar-thumb {
  background: #1f818c;
  webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

div::-webkit-scrollbar-thumb:hover {
  background: #1f818c;
  webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

/* ==========================================================================================
    Stats
  ========================================================================================== */

.stats-view {
  // .stats-card {
  // }

  @media screen and (max-width: 480px) {
    .stats-card {
      height: 200px;
      .card-title {
        margin-bottom: 15px !important;
      }
    }
  }

  .chart-card {
    position: relative;
    display: flex;
    padding: 0 10px;
  }
  .chart-label-container {
    position: relative;
    height: 350px;
    width: 35px;
  }
  .chart-label {
    position: absolute;
    left: -35px;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    color: #606973;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    white-space: nowrap;
    width: 100px;
    text-align: center;
  }
  .chart {
    flex: 1;
    width: 100%;
  }
  .nav-pills {
    .nav-link {
      cursor: pointer;
      &.active {
        color: #fff;
        background-color: #1f818c;
      }
    }
  }

  .stats-line-chart {
    .overview {
      color: black;
      font-weight: bolder;
    }
    .chart-title {
      color: black;
      font-size: 20px;
      font-weight: normal;
    }

    .stats-action {
      justify-content: flex-start;
      .form-group {
        width: 100%;
      }
      .btn {
        padding: 0.625rem 0rem;
        text-align: center;
        width: 100%;
      }
    }
    .stats-header {
      margin-bottom: 20px;
    }
    .stats-action-row {
      width: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      .nav-pills {
        .nav-item {
          margin-bottom: 0 !important;
        }
      }
    }

    @media screen and (min-width: 768px) {
      .stats-action {
        justify-content: flex-end;
        .form-group {
          width: 340px;
        }
        .btn {
          padding: 0.625rem 1.25rem;
        }
      }
      .stats-header {
        margin-bottom: 20px;
      }
      .stats-action-row {
        width: initial;
        flex: initial;
      }
    }
  }

  .card-stats {
    flex: 1;
    .card-body {
      padding: 1rem;
    }
    h5 {
      font-size: 13px;
      font-weight: bolder;
    }
    .stats-icon {
      width: 20px;
    }
  }
}

/* ==========================================================================================
    Settings
  ========================================================================================== */

.email-outer-container {
  position: relative;
  padding: 30px;
  .header {
    left: initial;
    transform: initial;
  }
}

.settings-view {
  .full-height-card {
    min-height: calc(100vh - 200px);
  }
}

.settings-view {
  .full-height-card {
    min-height: calc(100vh - 200px);
  }
  .email-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    .email-image {
      width: 150px;
    }
  }

  .email-outer-container {
    padding: 30px;
    max-width: 600px;
  }
  .email-inner-container {
    background: white;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    h1 {
      font-size: 25px;
      text-align: center;
    }
    p {
      font-size: 15px;
      font-weight: bold;
      text-align: center;
    }
    label {
      display: block;
      font-size: 13px;
      text-align: center;
      margin-bottom: 30px;
    }
    img {
      width: 100px;
    }
  }
}
