.euiOverlayMask {
  background: rgba(0, 0, 0, 0.2) !important;
}
.euiModal {
  .euiButtonIcon {
    .euiIcon {
      fill: #9d9d9d !important;
      width: 25px !important;
      height: 25px !important;
    }
  }
  &.euiModal--noCloseButton {
    .euiButtonIcon {
      display: none;
    }
  }
}

.modal-scan-parcel-step {
  @media only screen and (min-width: 1200px) and (max-height: 800px) {
    img {
      width: 250px !important;
    }
  }
  @media only screen and (min-width: 1600px) and (max-height: 900px) {
    img {
      width: 300px !important;
    }
  }
}
