@import '../colors/index.scss';

// Dropzone
.drop-file-component {
  @media (min-width: 576px) {
    max-width: 567px !important;
    width: 567px !important;
  }
  .euiFilePicker__prompt {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px 25px;
    border: none;
    outline: none;
    border-radius: 22px;
    background-color: $primaryLight !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    color: $textDark !important;
    transition: border 0.24s ease-in-out;
    margin-bottom: 20px;
    max-width: 580px;
    cursor: pointer;
    min-height: 340px;
    .euiFilePicker__promptText {
      text-align: center;
      overflow: inherit !important;
      text-overflow: inherit !important;
      white-space: inherit !important;
      word-wrap: inherit !important;
    }
  }
  .euiFilePicker__icon {
    width: 80px;
    height: 80px;
    fill: #1c1c1c;
  }

  &.hover {
    border-color: #172b4d;
    transform: scale(1.01);
  }
  &.uploaded {
    border: 2px solid #172b4d;
  }
  .filename {
    font-size: 18px;
    color: #172b4d;
    margin: 0;
    font-weight: bold;
  }
  .close-file {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
    border: none;
    background: none;
    z-index: 99;
    cursor: pointer;
    i {
      color: #606973;
    }
  }
  .document-icon {
    font-size: 60px;
    color: #172b4d;
    margin-bottom: 20px;
  }
  .upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
  }
}
