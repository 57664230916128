@import '../colors/index.scss';

.notificationEditorField {
  border-radius: 6px !important;
  background-color: $euiInputBackground !important;
  line-height: normal !important;
  display: block !important;
  overflow: auto !important;
  max-height: 300px !important;
  max-width: 400px !important;
  padding: 12px !important;
  border-radius: 6px !important;
  box-shadow: 0 0 transparent, inset 0 0 0 1px rgb(38 65 89 / 10%) !important;
  transition: box-shadow 150ms ease-in, background-image 150ms ease-in, background-size 150ms ease-in, background-color 150ms ease-in !important;
  
  &--isLoading {
    padding-right: 40px !important;
  }

  p {
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  code {
    font-family: 'Courier' !important;
    padding: 2px;
    background-color: $emailTemplateCodeBackground;
  }

  ol {
    list-style: decimal !important;
  }

  ul {
    list-style: disc !important;
  }

  li {
    font-size: 14px !important;
    margin-left: 15px !important;
  }

  h1 {
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h3 {
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h4 {
    display: block;
    font-size: 1em;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h5 {
    display: block;
    font-size: .83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h6 {
    display: block;
    font-size: .67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  blockquote {
    border-left: 2px solid #dedede;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    font-style: italic;
    font-size: 14px !important;
    color: #a3a3a3;
  }

  &:focus {
    background-color: #fff !important;
    background-image: linear-gradient(to top, #1f818c, #1f818c 2px, transparent 2px, transparent 100%) !important;
    background-size: 100% 100% !important;
    outline: none !important;
    box-shadow: inset 0 0 0 1px rgb(38 65 89 / 10%) !important;
  }
}

.notificationEditorFieldToolbar {
  gap: 7px;
  border-bottom: 1px solid rgb(38 65 89 / 10%);
}
