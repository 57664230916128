//Deepfinity Themes
$primaryForeground: hsl(0, 0%, 11%);
$primaryBackground: #ffffff;
$primaryLight: #f6f7fa;
$primaryMid: #1f818c;
$primaryDark: #196770;

$secondaryLight: #edf1f6;
$secondaryMid: #e7ecf1;
$secondaryDark: #7b89a4;

$accentLight: #f7da4b;
$accentMid: #1189bf;

$textLight: #ffffff;
$textMid: #7383a1;
$textMidDark: #465063;
$textDark: #1c1c1c;
$textGrey: #a2abba;
$textDestructive: #000000;

$infoLight: #b8dce1;
$infoMid: #79b3ba;
$infoDark: #62a7af;

$successLight: #65b0b8;
$successMid: #2a838e;
$successDark: #196770;

$warningLight: #ffb589;
$warningMid: #ff9b5f;
$warningDark: #f17d39;
$warningDestructive: #d65b12;

$dangerLight: #ff9ba2;
$dangerMid: #ff6873;
$dangerDark: #ff3d4d;
$dangerDestructive: #bd271e;

$scanOverlay: #b0b8dce1;
$backgroundArea: #ceb4b4b4;
$white: #ffffff;
$black: #000000;

$euiInputBackground: #f6f7fa;

$emailTemplateCodeBackground: #eee;

/* ==========================================================================================
  Core
========================================================================================== */
$euiColorPrimary: $primaryMid;
$euiColorAccent: $accentLight;
$euiColorSuccess: $successMid;
$euiColorWarning: $dangerDark;
$euiColorDanger: $dangerDestructive;
$euiColorGhost: $primaryLight;

/* ==========================================================================================
  Fonts
========================================================================================== */
$euiColorPrimaryText: $textDark;
$euiColorSecondaryText: $textMidDark;
$euiColorDangerText: $dangerDestructive;
$euiColorAccentText: $accentLight;
$euiTextSubduedColor: $primaryMid;
$euiTextColor: $textDark;
$euiTitleColor: $textDark;
