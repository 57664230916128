@import '../colors/index.scss';

.euiButton {
  text-decoration: none !important;
  &:hover {
    text-decoration: none !important;
  }
  .euiButton__text {
    color: $textLight;
  }
  &.euiButton--warning {
    &.euiButton--fill {
      background-color: #ff3d4d;
      border-color: #ff3d4d;
      color: #fff;
      .euiButtonContent {
        color: #fff;
      }
    }
  }

  &.euiButton-isDisabled {
    .euiButton__text,
    .euiButton__text > p {
      color: $textGrey !important;
    }
  }

  &:not(.euiButton--fill):not(:hover) {
    background-color: transparent !important;
  }

  &.euiButton--fill {
    .euiIcon {
      color: #fff;
    }
    &:focus-visible {
      outline: 3px solid black;
    }
  }

  &:focus-visible {
    outline: 3px solid black;
  }
}

.euiLink {
  font-weight: bold;
  text-decoration: underline;
  width: fit-content;
}
