//Sofia Fonts
@import url(https://use.typekit.net/xoi5ihh.css);

$euiFontFamily: 'sofia-pro' !important;
$euiCodeFontFamily: 'sofia-pro' !important;

@mixin euiFont {
  font-family: $euiFontFamily;
  font-weight: $euiFontWeightRegular;
  letter-spacing: normal;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-kerning: normal;
}

@mixin euiFontSizeXS {
  @include fontSize($euiFontSizeXS);
  @include lineHeightFromBaseline(2);
}

@mixin euiFontSizeS {
  @include fontSize($euiFontSizeS);
  @include lineHeightFromBaseline(3);
}

@mixin euiFontSize {
  @include fontSize($euiFontSize);
  @include lineHeightFromBaseline(3);
}

@mixin euiFontSizeM {
  @include fontSize($euiFontSizeM);
  @include lineHeightFromBaseline(3);
}

@mixin euiFontSizeL {
  @include fontSize($euiFontSizeL);
  @include lineHeightFromBaseline(4);
}

@mixin euiFontSizeXL {
  @include fontSize($euiFontSizeXL);
  @include lineHeightFromBaseline(4);
}

@mixin euiFontSizeXXL {
  @include fontSize($euiFontSizeXXL);
  @include lineHeightFromBaseline(5);
}
