.euiFlyout {
  border: none !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) !important;
  .euiListGroup {
    .euiListGroupItem.euiListGroupItem-isClickable:hover .euiListGroupItem__button,
    .euiListGroupItem .euiListGroupItem__button:hover,
    .euiListGroupItem .euiListGroupItem__button:focus {
      text-decoration: none !important;
    }
  }
}
