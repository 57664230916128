.react-datepicker__navigation.react-datepicker__navigation--next.react-datepicker__navigation--next--with-time {
  left: auto !important;
  transform: none !important;
  transition: 90ms ease-in-out !important;

  &:hover {
    transform: rotate(-90deg) !important;
  }
}

.react-datepicker__year-dropdown {
  position: initial !important;
}

.react-datepicker__month-dropdown {
  height: 300px !important;
}

@media only screen and (max-width: 574px) {
  .react-datepicker__time-box.react-datepicker__time-box--accessible {
    height: 48px !important;
  }
}