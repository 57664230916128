@import '../colors/index.scss';

.euiTabs--bottomBorder {
  box-shadow: none !important;
}

.euiTab.euiTab-isSelected {
  box-shadow: inset 0 -2px 0 $primaryMid !important;
}

.euiTab.euiTab-isSelected, .euiTabs .euiTab.euiTab-isSelected .euiTab__content {
  color: $primaryMid !important;
}
