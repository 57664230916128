.module-onboarding-page {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  @media screen and (min-width: 1920px) {
    background-position: bottom;
    background-position-y: calc(100vh - 53vh);
  }
}

.module-onboarding {
  flex: 1;
  align-self: center;
}

.module-onboarding-footer {
  justify-content: space-between;
  margin: 10px 0 0 0 !important;
}

.module-onboarding-logo {
  align-items: center;
}
//Media queries
@media only screen and (max-width: 574px) {
  .module-onboarding {
    padding: 20px;
  }
  .module-onboarding-logo {
    align-items: center;
    justify-content: center;
  }
  .module-onboarding-footer {
    align-items: start;
  }
}

//Onboarding modal
.module-onboarding-modal {
  border-radius: 35px !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) !important;
  max-height: initial !important;

  .euiModal__flex {
    max-height: initial !important;
    @media only screen and (max-width: 574px) {
      max-height: 100vh !important;
    }
  }

  .euiModal__closeIcon {
    right: 25px;
    top: 15px;
  }
  .module-onboarding-modal--body {
    .euiModalBody__overflow {
      display: flex;
      flex-direction: column;
      padding: 10px 0;
    }
    .module-onboarding-modal-steps {
      background: none;
      margin-bottom: 0;
      width: 400px;
      @media only screen and (max-width: 574px) {
        max-height: 90% !important;
      }
    }
  }
  .module-onboarding-modal--body {
    .euiModalBody__overflow {
      display: flex;
      flex-direction: column;
      padding: 10px 0;
    }
    .module-onboarding-modal-steps {
      background: none;
      margin-bottom: 20px;
    }
  }
}
