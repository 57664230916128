.euiHeader {
  .euiAvatar {
    .euiButtonEmpty {
      letter-spacing: 2px;
    }
  }
  .euiButtonEmpty {
    display: flex;
    align-items: center;
    span {
      color: $white;
    }
  }
}
