@import '../colors/index.scss';

.tagify__input {
  border-radius: 6px !important;
  background-color: $euiInputBackground !important;
  display: block;
  overflow: auto;
  max-height: 180px;

  &:focus {
    background-color: #fff !important;
    outline: none !important;
    border-bottom: 1px solid $primaryMid;
  }
}
