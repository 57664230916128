.euiStepsHorizontal {
  background: none !important;
  margin-bottom: 0;
}

.euiStepNumber {
  font-size: 14px !important;
  font-weight: bold !important;
}

.euiStepNumber--incomplete {
  color: #a2abba !important;
  background-color: #f7f7f9 !important;
}

.euiStepNumber--complete {
  color: #fff !important;
}

.euiStepHorizontal::before,
.euiStepHorizontal::after {
  height: 3px !important;
  top: 39px !important;
}
