// Deepfinity overrides
@import 'global_styling/index';

// This is the default theme.
@import '@elastic/eui/src/themes/amsterdam/_colors_light';

@import '@elastic/eui/src/themes/legacy/legacy_light';
@import '@elastic/eui/src/themes/amsterdam/theme_light';


// Global styling
@import 'global_styling/colors/index';
@import '@elastic/eui/src/themes/amsterdam/global_styling/index';

// Components
@import '@elastic/eui/src/components/index';

// Component overrides
// Comes after the component import and overrides via cascade
@import '@elastic/eui/src/themes/amsterdam/overrides/index';

body {
  @include euiHeaderAffordForFixed($euiHeaderHeightCompensation);
  &.euiBody--headerIsFixed {
    padding-top: 49px !important;
    @media (min-width: 1200px) {
      padding-left: 185px !important;
    }
  }
}
