.google-places-autocomplete-container {
  width: 100%;
  position: relative;
  margin-bottom: 0;
  .google-places-autocomplete__control {
    min-height: 40px !important;
    border: none;
    box-shadow: none;
    &:focus {
      background-color: white;
      background-image: linear-gradient(to top, #1f818c, #1f818c 2px, transparent 2px, transparent 100%);
      background-size: 100% 100%;
      transition: box-shadow 150ms ease-in, background-image 150ms ease-in, background-size 150ms ease-in,
        background-color 150ms ease-in;
    }
    .google-places-autocomplete__single-value {
      margin-left: 10px;
      font-size: 14px;
      overflow: initial;
    }
    .google-places-autocomplete__value-container {
      padding: 0;
      overflow: initial;
    }
    .google-places-autocomplete__indicators {
      display: none;
    }
    .google-places-autocomplete__input {
      max-width: 400px;
      width: 400px;
      height: 40px;
      background-color: #fbfcfd;
      background-repeat: no-repeat;
      background-size: 0% 100%;
      box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(37, 65, 89, 0.1);
      transition: box-shadow 150ms ease-in, background-image 150ms ease-in, background-size 150ms ease-in,
        background-color 150ms ease-in;
      font-family: 'Sofia Pro Light', 'Sofia Pro Medium', 'Sofia Pro Bold', 'Sofia Pro Regular';
      font-weight: 400;
      letter-spacing: -0.005em;
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
      font-feature-settings: 'kern';
      -webkit-font-kerning: normal;
      font-kerning: normal;
      font-size: 14px;
      color: #04262c;
      border: none;
      border-radius: 0;
      padding: 12px;
      &:focus {
        background-color: white;
        background-image: linear-gradient(to top, #1f818c, #1f818c 2px, transparent 2px, transparent 100%);
        background-size: 100% 100%;
      }
    }
  }
}

.google-places-autocomplete__control--is-focused {
  .google-places-autocomplete__input {
    background-color: white !important;
    background-image: linear-gradient(to top, #1f818c, #1f818c 2px, transparent 2px, transparent 100%) !important;
    background-size: 100% 100% !important;
  }
}

.google-places-autocomplete__suggestions-container {
  position: absolute;
  background: white;
  width: 100%;
  left: 0;
  box-shadow: 0px 3px 6px rgba(50, 50, 93, 0.25), 5px 2px 6px rgba(0, 0, 0, 0.05);
  padding: 1rem;
  z-index: 9999;
  div {
    font-size: 14px;
    margin-bottom: 15px;
  }
}
