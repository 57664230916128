.ant-image {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  height: 100% !important;
  .ant-image-img {
    object-fit: cover;
  }
}
