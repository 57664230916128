.module-dashboard {
  .module-dashboard__dashboard {
    margin: 0;
    width: 100%;
    max-width: 1560px;
  }

  .module-dashboard-map-file-step-table {
    .euiTableFooterCell .euiTableCellContent__text,
    .euiTableHeaderCell .euiTableCellContent__text {
      font-size: 15px;
    }
    .euiTableRowCell {
      border-top: 5px solid #ebebeb;
      border-bottom: 5px solid #ebebeb;
    }
  }
  .module-dashboard-map-file-step-table-validation {
    .euiTableRow.validated {
      .euiTableRowCell {
        border-bottom: 4px solid #66cc36;
      }
    }
    .euiTableRow.error {
      .euiTableRowCell {
        border-bottom: 4px solid #bd271e;
      }
    }
  }
}

.module-dashboard-steps {
  .euiTitle {
    display: none !important;
  }
  .euiStep__content {
    margin-top: -40px;
    padding: 16px 16px 20px 15px;
  }
  .euiStep:not(:last-of-type) {
    background-image: none !important;
  }
  .euiStepNumber {
    background: transparent;
    border: 1px solid black;
    line-height: 19px !important;
    width: 22px !important;
    height: 22px !important;
    color: black !important;
    font-weight: normal !important;
    &.euiStepNumber--complete {
      background: #c0c1c4;
      color: white !important;
      border-color: #c0c1c4;
    }
  }
}
