.euiLink {
  outline: none !important;
  outline-offset: 1px !important;

  &:focus {
    outline: none !important;
  }
  &:focus-visible {
    outline: 1px solid black !important;
  }
}
