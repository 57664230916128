.radio-group-with-images-container {
  margin: 0;
  width: 575px;
  justify-content: space-between;
  .radio-group-with-images-item {
    width: 250px;
    margin: 0;
    .euiCheckableCard:not(.euiCheckableCard-isDisabled).euiCheckableCard-isChecked {
      border: none;
      background-color: #ebebeb;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }
    .radio-group-with-images-card-container {
      width: 250px;
      margin: 0;
    }
    .radio-group-with-images-card {
      width: 250px;
      height: 270px;
      margin-bottom: 15px;
      border: none;
      border-radius: 40px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      .euiCheckableCard__label {
        visibility: hidden;
      }
      .euiCheckableCard__children {
        padding: 0;
        flex: 1;
      }
      .euiCheckableCard__control {
        background: none;
        width: 0px;
        .euiRadio .euiRadio__input + .euiRadio__circle {
          padding: 7px;
          border: 1px solid #ebebeb;
          background: #ebebeb no-repeat center;
          border-radius: 14px;
          transition: background-color 150ms ease-in, border-color 150ms ease-in;
          display: inline-block;
          position: absolute;
          left: 10px;
          top: 15px;
          width: 30px;
          height: 30px;
        }
        .euiRadio .euiRadio__input:checked + .euiRadio__circle {
          border-color: #21808b;
          background-color: #21808b;
          background-image: initial;
        }
      }
      .radio-group-with-images-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 190px;
        height: 190px;
        .radio-group-with-images-image {
          height: 100%;
        }
      }
    }
    .radio-group-with-images-legend {
      padding: 5px;
      text-align: center;
      justify-content: center;
    }
  }
}
